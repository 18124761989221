import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import Slider from "react-slick";

class BlogRollWorks extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;
    const settings = {
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
      cssEase: "linear",
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    };

    return (
      <Slider {...settings}>
        {posts &&
          posts.map(({ node: post }) => (
            <div key={post}>
              <Link to={post.fields.slug} className='zoom-in-img'>
                {post.frontmatter.featuredimage ? (
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: post.frontmatter.featuredimage,
                      alt: post.frontmatter.title,
                      width: post.frontmatter.featuredimage.childImageSharp.gatsbyImageData.width,
                      height: post.frontmatter.featuredimage.childImageSharp.gatsbyImageData.height,
                    }}
                  />
                ) : null}
              </Link>
            </div>
          ))}
      </Slider>
    );
  }
}

BlogRollWorks.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default function BlogRoll() {
  return (
    <StaticQuery
      query={graphql`
        query BlogRollWorksQuery {
          allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }, filter: { frontmatter: { templateKey: { eq: "blog-post" }, tags: { eq: "制作実績" } } }) {
            edges {
              node {
                excerpt(pruneLength: 400)
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  templateKey
                  date(formatString: "YYYY/MM/DD")
                  featuredpost
                  featuredimage {
                    childImageSharp {
                      gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <BlogRollWorks data={data} count={count} />}
    />
  );
}
