import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";

import Layout from "../components/Layout";
import Features from "../components/Features";
import BlogRoll from "../components/BlogRoll";
import BlogRollWorks from "../components/BlogRollWorks";
import * as FadeIn from "../components/fadeIn";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeadset, faSitemap, faTools, faBoxOpen } from "@fortawesome/free-solid-svg-icons";

import Particles from "react-tsparticles";

export const IndexPageTemplate = ({ image, title, heading, subheading, mainpitch, description, intro }) => (
  <div>
    <div
      style={{
        position: "absolute",
        left: "0",
        right: "0",
        top: "40vh",
        textAlign: "center",
        zIndex: "10",
      }}
    >
      <FadeIn.Up>
        <h1
          className='orbitron is-size-4 is-size-5-touch'
          style={{
            textShadow: "1px 2px 3px #808080",
            color: "white",
          }}
        >
          {title}
        </h1>
        <img className='top-logo' src='/img/logo_white.svg' alt='V-CONNECT' />
      </FadeIn.Up>
    </div>
    <Particles
      id='tsparticles'
      width='100vw'
      height='100vh'
      options={{
        background: {
          color: {
            value: "#000000",
          },
          image: "url('https://particles.js.org/images/background3.jpg')",
          position: "50% 50%",
          repeat: "no-repeat",
          size: "cover",
        },
        backgroundMask: {
          cover: {
            color: {
              value: {
                r: 0,
                g: 0,
                b: 0,
              },
            },
          },
          enable: true,
        },
        fullScreen: {
          enable: false,
        },
        interactivity: {
          events: {
            onClick: {
              enable: true,
              mode: "push",
            },
            onHover: {
              enable: true,
              mode: "bubble",
              parallax: {
                force: 60,
              },
            },
          },
          modes: {
            bubble: {
              distance: 400,
              duration: 2,
              opacity: 1,
              size: 100,
            },
            grab: {
              distance: 400,
            },
          },
        },
        particles: {
          color: {
            value: "#ffffff",
          },
          links: {
            color: {
              value: "#ffffff",
            },
            distance: 150,
            enable: true,
          },
          move: {
            attract: {
              rotate: {
                x: 600,
                y: 1200,
              },
            },
            enable: true,
            outModes: {
              bottom: "out",
              left: "out",
              right: "out",
              top: "out",
            },
          },
          number: {
            density: {
              enable: true,
            },
            value: 80,
          },
          opacity: {
            animation: {
              speed: 1,
              minimumValue: 0.1,
            },
          },
          size: {
            random: {
              enable: true,
            },
            value: {
              min: 1,
              max: 30,
            },
            animation: {
              speed: 40,
              minimumValue: 0.1,
            },
          },
        },
      }}
    />
    <div className='container'>
      <div className='section pt-0'>
        <div className='columns'>
          <div className='column is-10 is-offset-1 content'>
            <section id='service'>
              <FadeIn.Up>
                <div className='columns'>
                  <div className='column is-12'>
                    <h2 className='heading orbitron mt-6'>{heading}</h2>
                  </div>
                </div>
                <Features gridItems={intro.blurbs} />
              </FadeIn.Up>
            </section>
            <section id='site'>
              <FadeIn.Up>
                <h2 className='heading mt-6'>制作サイト</h2>
                <div className='columns'>
                  <div className='column is-4'>
                    <div className='border-box'>コーポレートサイト</div>
                  </div>
                  <div className='column is-4'>
                    <div className='border-box'>ランディングページ</div>
                  </div>
                  <div className='column is-4'>
                    <div className='border-box'>レスポンシブ対応</div>
                  </div>
                </div>
                <div className='columns'>
                  <div className='column is-4'>
                    <div className='border-box'>WordPress実装</div>
                  </div>
                  <div className='column is-4'>
                    <div className='border-box'>採用サイト制作</div>
                  </div>
                </div>
              </FadeIn.Up>
            </section>
            <section id='flow'>
              <FadeIn.Up>
                <h2 className='heading mt-6'>制作の流れ</h2>
                <div className='columns has-text-centered'>
                  <div className='column is-3'>
                    <section className='h-100 box'>
                      <h3>ヒアリング</h3>
                      <FontAwesomeIcon icon={faHeadset} className='is-size-1' />
                      <p className='has-text-left mt-3'>
                        お客様の予定に合わせてヒアリングを実施させていただきます。
                        <br />
                        対面でもオンラインでも対応可能です。
                      </p>
                    </section>
                  </div>
                  <div className='column is-3'>
                    <section className='h-100 box'>
                      <h3>構成作成</h3>
                      <FontAwesomeIcon icon={faSitemap} className='is-size-1' />
                      <p className='has-text-left mt-3'>ヒアリング内容を基にサイトマップ、ワイヤーフレームを作成し、制作スケジュールを確定します。</p>
                    </section>
                  </div>
                  <div className='column is-3'>
                    <section className='h-100 box'>
                      <h3>WEBサイト作成</h3>
                      <FontAwesomeIcon icon={faTools} className='is-size-1' />
                      <p className='has-text-left mt-3'>サイトデザインを行い、デザインが確定後、実装を行います。</p>
                    </section>
                  </div>
                  <div className='column is-3'>
                    <section className='h-100 box'>
                      <h3>納品</h3>
                      <FontAwesomeIcon icon={faBoxOpen} className='is-size-1' />
                      <p className='has-text-left mt-3'>お客様指定の方法で納品をさせていただきます。</p>
                    </section>
                  </div>
                </div>
              </FadeIn.Up>
            </section>
            <section id='work'>
              <FadeIn.Up>
                <h2 className='heading mt-6'>制作実績</h2>
                <BlogRollWorks />
              </FadeIn.Up>
            </section>
            <section id='about'>
              <FadeIn.Up>
                <h2 className='orbitron heading mt-6'>About Us</h2>
                <table className='table is-striped'>
                  <tbody>
                    <tr>
                      <td>社名</td>
                      <td>株式会社V-CONNECT (ブイ　コネクト)</td>
                    </tr>
                    <tr>
                      <td>拠点</td>
                      <td>
                        本社：〒103-0026 東京都中央区日本橋兜町3-3兜町平和ビル7F
                        <br />
                        銀座オフィス：〒104-0061 東京都中央区銀座8-16-5 銀座中央ビル2F
                      </td>
                    </tr>
                    <tr>
                      <td>連絡先</td>
                      <td>
                        TEL：03-6260-6550
                        <br />
                        FAX：03-6260-6551
                      </td>
                    </tr>
                    <tr>
                      <td>設立</td>
                      <td>2016年05月13日 </td>
                    </tr>
                    <tr>
                      <td>グループ</td>
                      <td>
                        株式会社Vホールディングス
                        <br />
                        株式会社Vカレンシー
                        <br />
                        株式会社Vネクスト
                        <br />
                        株式会社Vディオーナ
                      </td>
                    </tr>
                    <tr>
                      <td>代表取締役</td>
                      <td>中島　和賢 </td>
                    </tr>
                    <tr>
                      <td>従業員数</td>
                      <td>
                        15名　(2017年7月末)
                        <br />
                        3000名（グループを含む）
                      </td>
                    </tr>
                    <tr>
                      <td>取引銀行</td>
                      <td>三井住友銀行　赤坂支店 </td>
                    </tr>
                    <tr>
                      <td>事業内容</td>
                      <td>
                        WEBサイト制作
                        <br />
                        WEBサイト運用
                        <br />
                        システム開発
                      </td>
                    </tr>
                  </tbody>
                </table>
              </FadeIn.Up>
            </section>
            <section id='access'>
              <FadeIn.Up>
                <h2 className='orbitron heading mt-6'>Access</h2>
                <iframe title='GoogleMap' src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.4509877284063!2d139.76156886525842!3d35.665895830197826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188bdd43ca77eb%3A0x68d2e6dac19707f5!2z44CSMTA0LTAwNjEg5p2x5Lqs6YO95Lit5aSu5Yy66YqA5bqn77yY5LiB55uu77yR77yW4oiS77yVIOmKgOW6p-S4reWkruODk-ODqw!5e0!3m2!1sja!2sjp!4v1500885149124' width='100%' height='450' frameBorder='0' allowFullScreen=''></iframe>
              </FadeIn.Up>
            </section>
            <section id='recruit'>
              <FadeIn.Up>
                <h2 className='orbitron heading mt-6'>Recruit</h2>
                <a href='https://en-gage.net/v-connect/?banner=1' target='_blank'>
                <picture>
                        <source media='(max-width: 1023px)' srcset='https://en-gage.net/imageFile_new/company/widget_banner_B_sp_320×100.png' style={{ width: '100%' }}/>
                        <source media="(min-width: 1024px)" srcset='https://en-gage.net/imageFile_new/company/widget_banner_B_pc_728×90.png' style={{ width: '100%' }}/>
                        <img src='https://en-gage.net/imageFile_new/company/widget_banner_B_pc_728×90.png' alt='engage' border='0' style={{ width: '100%' }}/>
                    </picture>
                    </a> 
              </FadeIn.Up>
            </section>
            <section id='information' style={{ display: `none` }}>
              <FadeIn.Up>
                <h2 className='orbitron heading mt-6'>Information</h2>
                <BlogRoll />
                <div className='column is-12 has-text-centered'>
                  <Link className='button' to='/blog'>
                    もっと見る
                  </Link>
                </div>
              </FadeIn.Up>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
);

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate image={frontmatter.image} title={frontmatter.title} heading={frontmatter.heading} subheading={frontmatter.subheading} mainpitch={frontmatter.mainpitch} description={frontmatter.description} intro={frontmatter.intro} />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(width: 2048, quality: 100)
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            heading
            image {
              childImageSharp {
                gatsbyImageData(width: 240, quality: 64)
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`;
